import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Rick’s Jerk Food Truck",
  pageDescription: "A caribbean food truck transitions to brick and mortar.",
  title: "Stress Free Tech at Scale",
  subTitle: `How SpotOn helps Rick’s Jerk Food Truck reach new customers and create a loyal following.`,
  businessName: "Rick’s Jerk",
  industry: "Restaurant, food truck",
  location: "Honolulu, HI",
  products: [
    spotonProducts.SIDEKICK,
    spotonProducts.ORDER,
    spotonProducts.LOYALTY,
    spotonProducts.CAPITAL,
  ],
  description:
    "When Damon Pitts started slinging late night jerk chicken and rice bowls from his food trailer, he felt he found his calling. The challenge became how to make it profitable. In a time of sky high food costs, a labor shortage, and supply chain issues, Pitts decided on fast casual as the future for Rick’s Jerk and focused on implementing tech and creating systems that make his business scalable.",
  quoteData: {
    imgName: "ricks-jerk-food-truck.png",
    quote: `“One of the biggest things for my business is the modifiers. With my old system, I had to write down modifications on the receipt. With SpotOn, I can modify when I place the order. It has helped us eliminate order errors.”`,
    personName: "Damon Pitts",
    personTitle: "Owner, Rick’s Jerk Food Truck",
  },
  goal: `Pitts needed personalized service to meet the shifting needs of his food trailer, food truck, and fast casual restaurant. He wanted to streamline the ordering process and reach new customers through online ordering, all while minimizing his labor spend.`,
  solution: `Integrated online ordering lets guests at nearby hotels scan a QR code to place pickup orders, increasing sales and reaching new guests. Pitts uses SpotOn Loyalty to reward repeat visits and retain local customers. With SpotOn Sidekick, Rick’s Jerk stays nimble. Pitts can accommodate rush periods by placing orders and taking payments quickly, and adapting his service without sacrificing the guest experience. Plus, when Rick’s Jerk was ready to expand, SpotOn Capital ensured Pitts could access fair financing.`,
  solutionReadMore: {
    text: `Read Damon’s story.`,
    url: `https://www.spoton.com/blog/well-seasoned-operation-ricks-jerk-honolulu/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `20%`, description: `Increase in sales` },
      {
        title: `25%`,
        description: `Faster ordering process`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Opening a barbecue joint with smart tech",
    imageName: "barbwire.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-06-29",
    href: "/case-studies/barbwire-barbecue",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Hot & ready without the hassle",
    imageName: "callies-hot-little-biscuit.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-10-05",
    href: "/case-studies/callies-hot-little-biscuit",
    goals: [
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: '"Slow" food meets fast tech',
    imageName: "blue-barn.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-06-24",
    href: "/case-studies/blue-barn",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
